

export const isValidForm = form => {
    return form.type
}

export const getPayload = form => {
    const payload = {
        ...form,
        item_id: form.item.id,
        table_number: form.table_number?.value || null,
    }
    delete payload.item
    return payload
}