/* eslint-disable */
import { base } from '@/utils/api'

const ENDPOINT = '/material-medicines'

export default {
    searchItems(clinicId, type, query) {
        const config = {
            params: { clinicId, type, query }
        };
        return base.get(`${ENDPOINT}/search`, config);
    },
    getItems(page, clinicId, tableId, query, types, status) {
        const config = {
            params: { page, clinicId, query, types, status }
        };
        return base.get(`${ENDPOINT}/${tableId}/table`, config);
    },
    getIds(tableId) {
        const config = {
            params: { tableId }
        };
        return base.get(`${ENDPOINT}/batch/ids`, config);
    },
    create(props) {
        return base.post(`${ENDPOINT}`, props);
    },
    update(id, props) {
        return base.put(`${ENDPOINT}/${id}`, props);
    },
    delete(id) {
        return base.delete(`${ENDPOINT}/${id}`);
    },
    deleteBatch(ids) {
        return base.put(`${ENDPOINT}/batch/delete`, { ids });
    },
}