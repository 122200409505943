<template>
    <b-modal
      id="mat-med-modal"
      size="xl"
      hide-header
      hide-footer
      centered
      @hidden="onHidden"
    >
        <div class="header d-flex flex-row justify-content-between">
            <p v-if="!form?.id" class="title">Novo Item</p>
            <p v-else class="title">Editar Item</p>
            <Close class="close" @click="close" />
        </div>
        <div class="content">
            <b-row>
                <b-col cols="12">
                    <b-form-group>
                        <label>Tipo</label>
                        <multiselect
                            id="type"
                            v-model="form.type"
                            :options="['Simpro', 'Brasindice']"
                            :showLabels="false"
                            :searchable="false"
                            :allowEmpty="false"
                            placeholder="Selecionar"
                            class="with-border"
                            :disabled="type || !!form.id"
                        >
                            <template slot="caret">
                                <div class="chevron">
                                    <ChevronDown />
                                </div>
                            </template>
                        </multiselect>
                    </b-form-group>
                </b-col>
            </b-row>

            <SimproProps 
                v-show="form.type === 'Simpro'"
                v-model="form.simpro"
                :validated="validated"
            />

            <BrasindiceProps 
                v-show="form.type === 'Brasindice'" 
                v-model="form.brasindice"
                :validated="validated"
            />
            
            <b-row>
                <b-col>
                    <b-button 
                        variant="primary" 
                        class="float-right" 
                        :loading="loading"
                        :disabled="loading"
                        @click="save"
                    >
                        Salvar
                    </b-button>
                </b-col>
            </b-row>
        </div>
    </b-modal>
</template>
<script>
import api from '@matMed/api'
import { isValidForm } from '@matMed/utils/validations'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
    components: {
        Close: () => import('@/assets/icons/close.svg'),
        // Info: () => import('@/assets/icons/info.svg'),
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
        SimproProps: () => import('@matMed/layouts/SimproProps'),
        BrasindiceProps: () => import('@matMed/layouts/BrasindiceProps'),
    },
    props: {
        tableId: {
            type: String,
            required: true
        },
        type: {
            type: String
        },
        item: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            form: { type: null },
            loading: false,
            validated: false,
            clinic: getCurrentClinic(),
        }
    },
    methods: {
        close() {
            this.$bvModal.hide('mat-med-modal')
        },
        onHidden() {
            this.form = { type: this.type }
            this.$emit('hidden')
        },
        isValidProp(prop) {
            if (!this.validated) return null
            return !!this.form[prop]
        },
        async save() {
            this.validated = true 
            if (!isValidForm(this.form)) return
            const payload = {
                clinic_id: this.clinic.id,
                table_id: this.tableId,
                type: this.form.type,
                [this.form.type.toLowerCase()]: this.form[this.form.type.toLowerCase()],
            }

            console.log('save ', payload);

            this.loading = true
            try {
                const { data } = !this.form.id ? await api.create(payload) : await api.update(this.form.id, payload)
                this.$toast.success(`Mat/Med salvo com sucesso!`)
                this.$emit('saved', data)
                this.close()
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                this.validated = false
                this.loading = false
            }
        },
    },
    watch: {
        type(value) {
            if (value) {
                this.form.type = value
            }
        },
        item(data) {
            if (!data) {
                this.form = { type: this.type }
                return
            }
            
            this.form = {
                ...data,
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .content {
        padding: 1rem;
    }
    .module-title {
        font-size: 16px;
        font-weight: 700;
        color: var(--blue-500);
        margin-bottom: 1rem;
    }
    .radio-group {
        margin-top: 7px;
    }
</style>
<style lang="scss">
#mat-med-modal {
    .modal-body {
        padding: 0 !important;
        .header {
            padding: 24px;
            border-bottom: 1px solid var(--neutral-200);
            .title {
                font-weight: 600;
                font-size: 18px;
                color: var(--type-active);
                margin: 0;
            }
            .close {
                width: 24px;
                height: 24px;
                fill: black;
                cursor: pointer;
            }
        }
    }
}
</style>